import React, { ReactElement, ReactNode } from "react";
import { css } from "@emotion/react";
import { SerializedStyles } from "@emotion/react/types";

import variables from "../../styles/variables";
import pxToRem from "../../styles/px-to-rem";
import { h4Regular } from "../../styles/typography";

const button = css`
  ${h4Regular};
  font-family: ${variables.marketingHeaderFontFamily};
  min-height: ${pxToRem(40)};
  padding: ${pxToRem(6)} ${pxToRem(24)};
  border-radius: ${variables.roundness2};
  border: ${pxToRem(2)} solid ${variables.colorBrandYellow};
  background: ${variables.colorBrandYellow};
  color: ${variables.colorMainBlue};
  transition: background-color ${variables.transitionSpeed} ease,
    color ${variables.transitionSpeed} ease,
    border-color ${variables.transitionSpeed} ease;

  &:hover {
    background: ${variables.colorMainBlue};
    color: ${variables.colorBrandYellow};

    path {
      fill: ${variables.colorWhite};
    }
  }

  &:focus,
  &:active {
    opacity: ${variables.disabledOpacity};
  }

  &:disabled {
    opacity: ${variables.disabledOpacity};
    cursor: not-allowed;
  }
`;

const layout = css`
  display: grid;
  justify-content: center;
  align-items: center;
  grid-auto-flow: column;
  grid-template-columns: auto;
`;

type Input = {
  children: ReactNode;
  style?: SerializedStyles;
  type?: "button" | "submit";
  disabled?: boolean;
  onClick: () => void;
};

const HeroButton = ({
  children,
  onClick,
  type = "button",
  disabled = false,
  style,
}: Input): ReactElement => {
  return (
    <button
      css={[button, style]}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      <span css={layout}>{children}</span>
    </button>
  );
};

export default HeroButton;
